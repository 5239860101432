import useTranslations from '../../hooks/useTranslations';

const useWordings = () => {
  const { translate } = useTranslations();

  const menuWordings = {
    home: translate('home'),
    NFTs: translate('NFTs'),
    gameModes: translate('gameModes'),
    roadmap: translate('roadmap'),
    team: translate('team'),
    whitepaper: translate('whitepaper'),
    preSale: translate('preSale'),
    play: translate('play'),
    moreInformation: translate('moreInformation')
  };

  const singersWordings = {
    ourMusicians: translate('ourMusicians'),
    musiciansDesc1: translate('musiciansDesc1'),
    musiciansDesc2: translate('musiciansDesc2'),
    musiciansDesc3: translate('musiciansDesc3')
  };

  const boxWordings = {
    mysteryBox: translate('mysteryBox'),
    mysteryBoxDesc1: translate('mysteryBoxDesc1'),
    mysteryBoxDesc2: translate('mysteryBoxDesc2'),
    basic: {
      title: translate('basic.title'),
      content1: translate('basic.content1'),
      content2: translate('basic.content2'),
      content3: translate('basic.content3')
    },
    common: {
      title: translate('common.title'),
      content1: translate('common.content1'),
      content2: translate('common.content2'),
      content3: translate('common.content3')
    },
    premium: {
      title: translate('premium.title'),
      content1: translate('premium.content1'),
      content2: translate('premium.content2'),
      content3: translate('premium.content3')
    },
    recommended: translate('recommended')
  };

  const stageWordings = {
    stages: translate('stages'),
    stagesDesc1: translate('stagesDesc1'),
    stagesDesc2: translate('stagesDesc2')
  };

  const gamesWordings = {
    overtimeAndDuels: translate('overtimeAndDuels'),
    overtimeAndDuelsDesc: translate('overtimeAndDuelsDesc'),
    school: translate('school'),
    schoolDesc: translate('schoolDesc'),
    presentations: translate('presentations'),
    presentationsDesc: translate('presentationsDesc')
  };

  const teamWordings = {
    dreamTeam: translate('dreamTeam'),
    greatTeamIsTheKey: translate('greatTeamIsTheKey')
  };

  const comunnityWordings = {
    ourComunnity: translate('ourComunnity'),
    ourComunnityDesc1: translate('ourComunnityDesc1'),
    ourComunnityDesc2: translate('ourComunnityDesc2')
  };

  const copyrightWordings = translate('copyright');

  const wordings = {
    menuWordings,
    singersWordings,
    boxWordings,
    stageWordings,
    gamesWordings,
    teamWordings,
    comunnityWordings,
    copyrightWordings
  };

  return wordings;
};

export default useWordings;
